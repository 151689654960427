import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';

import { access, doLogout } from '../services/user';

import CookieConsent from '../components/cookie-consent';
import { getConsent } from '../services/consent';
import SEO from '../components/seo';

const TermsAndConditions = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [consentStatus, setConsentStatus] = useState(getConsent());

  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('terms_accessed');
      }
    }
  }, [consentStatus]);

  const logout = () => {
    doLogout()
      .then(() => {
        setIsLogged(false);
      })
      .catch(() => false);
  };

  useEffect(() => {
    const userStatus = access();
    Promise.resolve(userStatus)
      .then(function (value) {
        setIsLogged(value);
      })
      .catch((err) => {
        setIsLogged(false);
      });
  }, []);

  const cookieUpdate = (data) => {
    setConsentStatus(data);
    setShowCookie(false);
  };

  const toggleCookie = () => {
    setShowCookie(true);
  };

  return (
    <div className="has-navbar-fixed-top terms-and-conditions">
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO
        title="Terms and conditions | Portica - All your projects in one place"
        description="Terms and Conditions to use Portica apps. Portica is a all-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects"
      ></SEO>
      <div>
        <Header
          logged={isLogged}
          consent={consentStatus}
          onLogout={logout}
          toggleCookie={toggleCookie}
        ></Header>
        <div className="portica-wrapper">
          <div className="section">
            <div className="is-stretch terms-and-conditions__content">
              <h1 className="title is-size-1">
                Portica Terms &amp; Conditions
              </h1>
              <p>
                Thanks for using Portica! These terms of service ("Terms") cover
                your use and access to our services, client software and
                websites ("Services"). Our Privacy Policy explains how we
                collect and use your information while our Acceptable Use Policy
                outlines your responsibilities when using our Services. By using
                our Services, you're agreeing to be bound by these Terms, and to
                review our Privacy and Acceptable Use policies. If you're using
                our Services for an organization, you're agreeing to these Terms
                on behalf of that organization.
              </p>

              <hr />

              <h3 className="title is-size-2">
                Your Content &amp; Your Permissions
              </h3>
              <p>
                When you use our Services, you provide us with things like your
                files, content, email messages, contacts and so on ("Your
                Content"). Your Content is yours. These Terms don't give us any
                rights to Your Content except for the limited rights that enable
                us to offer the Services.
              </p>
              <p>
                We need your permission to do things like hosting Your Content,
                backing it up, and sharing it when you ask us to. Our Services
                also provide you with features like photo thumbnails, document
                previews, email organization, easy sorting, editing, sharing and
                searching. These and other features may require our systems to
                access, store and scan Your Content. You give us permission to
                do those things, and this permission extends to our affiliates
                and trusted third parties we work with.
              </p>

              <hr />

              <h3 className="title is-size-2">Sharing Your Content</h3>
              <p>
                Our Services let you share Your Content with others, so please
                think carefully about what you share.
              </p>

              <hr />

              <h3 className="title is-size-2">Your Responsibilities</h3>
              <p>
                You're responsible for your conduct, Your Content and you must
                comply with our Acceptable Use Policy. Content in the Services
                may be protected by others' intellectual property rights. Please
                don't copy, upload, download or share content unless you have
                the right to do so.
              </p>
              <p>
                We may review your conduct and content for compliance with these
                Terms and our Acceptable Use Policy. With that said, we have no
                obligation to do so. We aren't responsible for the content
                people post and share via the Services.
              </p>
              <p>
                Please safeguard your password to the Services, make sure that
                others don't have access to it, and keep your account
                information current.
              </p>
              <p>
                Finally, our Services are not intended for and may not be used
                by people under the age of 13. By using our Services, you are
                representing to us that you're over 13.
              </p>

              <hr />

              <h3 className="title is-size-2">Beta Services</h3>
              <p>
                We sometimes release products and features that we are still
                testing and evaluating. Those Services have been marked beta,
                preview, early access, or evaluation (or with words or phrases
                with similar meanings) and may not be as reliable as Porticas
                other services, so please keep that in mind.
              </p>

              <hr />

              <h3 className="title is-size-2">Our Stuff</h3>
              <p>
                The Services are protected by copyright, trademark, and other US
                and foreign laws. These Terms don't grant you any right, title
                or interest in the Services, others' content in the Services,
                Portica trademarks, logos and other brand features. We welcome
                feedback, but note that we may use comments or suggestions
                without any obligation to you.
              </p>

              <hr />

              <h3 className="title is-size-2">Copyright</h3>
              <p>
                We respect the intellectual property of others and ask that you
                do too. We respond to notices of alleged copyright infringement
                if they comply with the law, and such notices should be
                reported. We reserve the right to delete or disable content
                alleged to be infringing and terminate accounts of repeat
                infringers.
              </p>

              <hr />

              <h3 className="title is-size-2">Paid Accounts</h3>
              <p>
                <em>Billing.</em> You can increase your account, adding paid
                features to your account (turning your account into a "Paid
                Account"). We'll automatically bill you from the date you
                convert to a Paid Account and on each periodic renewal until
                cancellation. You're responsible for all applicable taxes, and
                we'll charge tax when required to do so.
              </p>
              <p>
                <em>No Refunds.</em> You may cancel your Portica Paid Account at
                any time but you won't be issued a refund unless it's legally
                required.
              </p>
              <p>
                <em>Downgrades.</em> Your Paid Account will remain in effect
                until it's cancelled or terminated under these Terms. If you
                don't pay for your Paid Account on time, we reserve the right to
                suspend it or reduce your account to free space levels.
              </p>
              <p>
                <em>Changes.</em> We may change the fees in effect but will give
                you advance notice of these changes via a message to the email
                address associated with your account.
              </p>

              <hr />

              <h3 className="title is-size-2">Portica Business</h3>
              <p>
                <em>Email address.</em> If you sign up for a Portica account
                with an email address provisioned by your employer, your
                employer may be able to block your use of Portica until you
                transition to an account with a personal email address.
              </p>

              <hr />

              <h3 className="title is-size-2">Termination</h3>
              <p>
                You're free to stop using our Services at any time. We also
                reserve the right to suspend or end the Services at any time at
                our discretion and without notice. For example, we may suspend
                or terminate your use of the Services if you're not complying
                with these Terms, or use the Services in a manner that would
                cause us legal liability, disrupt the Services or disrupt
                others' use of the Services. Except for Paid Accounts, we
                reserve the right to terminate and delete your account if you
                haven't accessed our Services for 12 consecutive months. We'll
                of course provide you with notice via the email address
                associated with your account before we do so.
              </p>

              <hr />

              <h3 className="title is-size-2">Services "AS IS"</h3>
              <p>
                We strive to provide great Services, but there are certain
                things that we can't guarantee. TO THE FULLEST EXTENT PERMITTED
                BY LAW, PORTICA AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS
                MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE
                SERVICES. THE SERVICES ARE PROVIDED "AS IS." WE ALSO DISCLAIM
                ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE AND NON-INFRINGEMENT. Some places don't allow the
                disclaimers in this paragraph, so they may not apply to you.
              </p>

              <hr />

              <h3 className="title is-size-2">Limitation of Liability</h3>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR ANY LIABILITY
                FOR PORTICA OR ITS AFFILIATES’ FRAUD, FRAUDULENT
                MISREPRESENTATION, OR GROSS NEGLIGENCE, IN NO EVENT WILL
                PORTICA, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS BE LIABLE
                FOR:
              </p>
              <p>
                (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
                CONSEQUENTIAL DAMAGES, OR
              </p>
              <p>
                (B) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF
                LEGAL THEORY.
              </p>
              <p>
                THIS WILL BE REGARDLESS OF WHETHER OR NOT PORTICA OR ANY OF ITS
                AFFILIATES HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES,
                AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
              <p>
                ADDITIONALLY, PORTICA, ITS AFFILIATES, SUPPLIERS AND
                DISTRIBUTORS WILL NOT BE LIABLE FOR AGGREGATE LIABILITY FOR ALL
                CLAIMS RELATING TO THE SERVICES FOR MORE THAN THE GREATER OF $20
                OR THE AMOUNTS PAID BY YOU TO PORTICA FOR THE PAST 12 MONTHS OF
                THE SERVICES IN QUESTION.
              </p>
              <p>
                Some places don't allow the types of limitations in this
                paragraph, so they may not apply to you.
              </p>

              <hr />

              <h3 className="title is-size-2">Resolving Disputes</h3>
              <p>
                <em>Let's Try To Sort Things Out First.</em> We want to address
                your concerns without needing a formal legal case. Before filing
                a claim against Portica, you agree to try to resolve the dispute
                informally by contacting info@portica.co. We'll try to resolve
                the dispute informally by contacting you via email. If a dispute
                is not resolved within 45 days of submission, you or Portica may
                bring a formal proceeding.
              </p>
              <p>
                <em>Judicial forum for disputes.</em> You and Portica agree that
                any judicial proceeding to resolve claims relating to these
                Terms or the Services will be brought in the federal or state
                courts of Monroe County, Florida, subject to the mandatory
                arbitration provisions below. Both you and Portica consent to
                venue and personal jurisdiction in such courts.
              </p>

              <hr />

              <p className="is-size-2 has-text-weight-bold">
                IF YOU’RE A U.S. RESIDENT, YOU ALSO AGREE TO THE FOLLOWING
                MANDATORY ARBITRATION PROVISIONS:
              </p>
              <p>
                <em>We Both Agree To Arbitrate.</em> You and Portica agree to
                resolve any claims relating to these Terms or the Services
                through final and binding arbitration, except as set forth under
                Exceptions to Agreement to Arbitrate below.
              </p>
              <p>
                <em>Opt-out of Agreement to Arbitrate.</em> You can decline this
                agreement to arbitrate by submitting am emailed letter to
                opt-out within 30 days of first accepting these Terms.
              </p>
              <p>
                <em>Arbitration Procedures.</em> The American Arbitration
                Association (AAA) will administer the arbitration under its
                Commercial Arbitration Rules and the Supplementary Procedures
                for Consumer Related Disputes. The arbitration will be held in
                the United States county where you live or work, Key West (FL),
                or any other location we agree to.
              </p>
              <p>
                <em>Arbitration Fees and Incentives.</em> The AAA rules will
                govern payment of all arbitration fees. Portica will pay all
                arbitration fees for claims less than $5,000. If you receive an
                arbitration award that is more favorable than any offer we make
                to resolve the claim, we will pay you $1,000 in addition to the
                award. Portica will not seek its attorneys' fees and costs in
                arbitration unless the arbitrator determines that your claim is
                frivolous.
              </p>
              <p>
                <em>Exceptions to Agreement to Arbitrate.</em> Either you or
                Portica may assert claims, if they qualify, in small claims
                court in Key West (FL) or any United States county where you
                live or work. Either party may bring a lawsuit solely for
                injunctive relief to stop unauthorized use or abuse of the
                Services, or intellectual property infringement (for example,
                trademark, trade secret, copyright, or patent rights) without
                first engaging in arbitration or the informal dispute-resolution
                process described above. If the agreement to arbitrate is found
                not to apply to you or your claim, you agree to the exclusive
                jurisdiction of the state and federal courts in Monroe County,
                Florida to resolve your claim.
              </p>
              <p>
                <em className="has-text-weight-bold">NO CLASS ACTIONS.</em> You
                may only resolve disputes with us on an individual basis, and
                may not bring a claim as a plaintiff or a class member in a
                class, consolidated, or representative action. Class
                arbitrations, class actions, private attorney general actions,
                and consolidation with other arbitrations aren't allowed.
              </p>

              <hr />

              <h3 className="title is-size-2">Controlling Law</h3>
              <p>
                These Terms will be governed by Florida law except for its
                conflicts of laws principles, unless otherwise required by a
                mandatory law of any other jurisdiction.
              </p>

              <hr />

              <h3 className="title is-size-2">Entire Agreement</h3>
              <p>
                These Terms constitute the entire agreement between you and
                Portica with respect to the subject matter of these Terms, and
                supersede and replace any other prior or contemporaneous
                agreements, or terms and conditions applicable to the subject
                matter of these Terms. These Terms create no third party
                beneficiary rights.
              </p>

              <hr />

              <h3 className="title is-size-2">
                Waiver, Severability &amp; Assignment
              </h3>
              <p>
                Porticas failure to enforce a provision is not a waiver of its
                right to do so later. If a provision is found unenforceable, the
                remaining provisions of the Terms will remain in full effect and
                an enforceable term will be substituted reflecting our intent as
                closely as possible. You may not assign any of your rights under
                these Terms, and any such attempt will be void. Portica may
                assign its rights to any of its affiliates or subsidiaries, or
                to any successor in interest of any business associated with the
                Services.
              </p>

              <hr />

              <h3 className="title is-size-2">Modifications</h3>
              <p>
                We may revise these Terms from time to time, and will always
                post the most current version on our website. If a revision
                meaningfully reduces your rights, we will notify you (by, for
                example, sending a message to the email address associated with
                your account, posting on our blog or on this page). By
                continuing to use or access the Services after the revisions
                come into effect, you agree to be bound by the revised Terms.
              </p>

              <hr />

              <h3 className="title is-size-2">Portica Acceptable Use Policy</h3>
              <p>
                Portica is used by a lot of people, and we're proud of the trust
                placed in us. In exchange, we trust you to use our services
                responsibly.
              </p>
              <p>
                You agree not to misuse the Portica services ("Services") or
                help anyone else to do so. For example, you must not even try to
                do any of the following in connection with the Services:
              </p>
              <ul>
                <li>
                  probe, scan, or test the vulnerability of any system or
                  network;
                </li>
                <li>
                  breach or otherwise circumvent any security or authentication
                  measures;
                </li>
                <li>
                  access, tamper with, or use non-public areas or parts of the
                  Services, or shared areas of the Services you haven't been
                  invited to;
                </li>
                <li>
                  interfere with or disrupt any user, host, or network, for
                  example by sending a virus, overloading, flooding, spamming,
                  or mail-bombing any part of the Services;
                </li>
                <li>
                  access, search, or create accounts for the Services by any
                  means other than our publicly supported interfaces (for
                  example, "scraping" or creating accounts in bulk);
                </li>
                <li>
                  send unsolicited communications, promotions or advertisements,
                  or spam;
                </li>
                <li>
                  send altered, deceptive or false source-identifying
                  information, including "spoofing" or "phishing";
                </li>
                <li>
                  promote or advertise products or services other than your own
                  without appropriate authorization;
                </li>
                <li>
                  abuse referrals or promotions to get more storage space than
                  deserved;
                </li>
                <li>circumvent storage space limits;</li>
                <li>
                  sell the Services unless specifically authorized to do so;
                </li>
                <li>
                  publish or share materials that are unlawfully pornographic or
                  indecent, or that contain extreme acts of violence;
                </li>
                <li>
                  advocate bigotry or hatred against any person or group of
                  people based on their race, religion, ethnicity, sex, gender
                  identity, sexual preference, disability, or impairment;
                </li>
                <li>
                  violate the law in any way, including storing, publishing or
                  sharing material that's fraudulent, defamatory, or misleading;
                  or
                </li>
                <li>violate the privacy or infringe the rights of others.</li>
              </ul>

              <hr />

              <h3 className="title is-size-2">Contact</h3>
              <p>
                Have questions or concerns about Portica, our Services and
                privacy? Contact us at{' '}
                <a href="mailto:info@porticapro.com">info@porticapro.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
